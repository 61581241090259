var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-divider'),(_vm.userRole === 'division' )?_c('v-layout',{attrs:{"wrap":"","fill-height":""}},[_c('v-flex',{staticClass:"hidden-sm-and-down",attrs:{"xs12":"","sm12":"","md2":"","lg2":""}},[_c('v-navigation-drawer',{staticClass:"fixedSidebar",staticStyle:{"background-color":"#fafafa"},attrs:{"clipped":"","width":"100%","height":"100vh","permanent":""}},[_c('v-layout',{staticStyle:{"background-color":"#fff","height":"85px !important"}},[_c('v-flex',{attrs:{"xs12":"","pl-4":"","align-self-center":""}},[_c('v-img',{attrs:{"contain":"","width":"95%","height":"90%","src":require("../assets/sarpaodisha_1.png"),"fill-height":""}})],1)],1),_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.title,staticClass:"pr-0",staticStyle:{"letter-spacing":"0.63px"},attrs:{"link":"","to":item.path}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"pr-3"},[_vm._v(_vm._s(item.icon))]),_c('v-list-item-title',{staticStyle:{"font-size":"14px","font-family":"poppinsmedium"}},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md10":"","lg10":""}},[_c('v-layout',{attrs:{"wrap":"","pb-0":"","mb-0":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","pb-0":"","mb-0":""}},[_c('AppHeader',{staticClass:"pb-0 mb-0"})],1),_c('v-flex',{attrs:{"xs12":"","pt-0":"","mt-0":""}},[_c('v-divider',{staticClass:"pt-0 mt-0"})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","pl-3":""}},[_c('v-layout',{style:(_vm.$route.name == 'dashboard'
                ? 'background-color: #ffffff'
                : 'background-color: #f6fff9'),attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"min-height":"100vh","color":_vm.$route.name == 'dashboard' ? 'transparent' : '#FFFFFF',"flat":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.userRole === 'circle')?_c('v-layout',{attrs:{"wrap":"","fill-height":""}},[_c('v-flex',{staticClass:"hidden-sm-and-down",attrs:{"xs12":"","sm12":"","md2":"","lg2":""}},[_c('v-navigation-drawer',{staticClass:"fixedSidebar",staticStyle:{"background-color":"#fafafa"},attrs:{"clipped":"","width":"100%","height":"100vh","permanent":""}},[_c('v-layout',{staticStyle:{"background-color":"#fff","height":"85px !important"}},[_c('v-flex',{attrs:{"xs12":"","pl-4":"","align-self-center":""}},[_c('v-img',{attrs:{"contain":"","width":"95%","height":"90%","src":require("../assets/sarpaodisha_1.png"),"fill-height":""}})],1)],1),_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.items1),function(item){return _c('v-list-item',{key:item.title,staticClass:"pr-0",staticStyle:{"letter-spacing":"0.63px"},attrs:{"link":"","to":item.path}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"pr-3"},[_vm._v(_vm._s(item.icon))]),_c('v-list-item-title',{staticStyle:{"font-size":"14px","font-family":"poppinsmedium"}},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md10":"","lg10":""}},[_c('v-layout',{attrs:{"wrap":"","pb-0":"","mb-0":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","pb-0":"","mb-0":""}},[_c('AppHeader',{staticClass:"pb-0 mb-0"})],1),_c('v-flex',{attrs:{"xs12":"","pt-0":"","mt-0":""}},[_c('v-divider',{staticClass:"pt-0 mt-0"})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","pl-3":""}},[_c('v-layout',{style:(_vm.$route.name == 'dashboard'
                ? 'background-color: #ffffff'
                : 'background-color: #f6fff9'),attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"min-height":"100vh","color":_vm.$route.name == 'dashboard' ? 'transparent' : '#FFFFFF',"flat":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.userRole === 'range')?_c('v-layout',{attrs:{"wrap":"","fill-height":""}},[_c('v-flex',{staticClass:"hidden-sm-and-down",attrs:{"xs12":"","sm12":"","md2":"","lg2":""}},[_c('v-navigation-drawer',{staticClass:"fixedSidebar",staticStyle:{"background-color":"#fafafa"},attrs:{"clipped":"","width":"100%","height":"100vh","permanent":""}},[_c('v-layout',{staticStyle:{"background-color":"#fff","height":"85px !important"}},[_c('v-flex',{attrs:{"xs12":"","pl-4":"","align-self-center":""}},[_c('v-img',{attrs:{"contain":"","width":"95%","height":"90%","src":require("../assets/sarpaodisha_1.png"),"fill-height":""}})],1)],1),_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.items2),function(item){return _c('v-list-item',{key:item.title,staticClass:"pr-0",staticStyle:{"letter-spacing":"0.63px"},attrs:{"link":"","to":item.path}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"pr-3"},[_vm._v(_vm._s(item.icon))]),_c('v-list-item-title',{staticStyle:{"font-size":"14px","font-family":"poppinsmedium"}},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md10":"","lg10":""}},[_c('v-layout',{attrs:{"wrap":"","pb-0":"","mb-0":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","pb-0":"","mb-0":""}},[_c('AppHeader',{staticClass:"pb-0 mb-0"})],1),_c('v-flex',{attrs:{"xs12":"","pt-0":"","mt-0":""}},[_c('v-divider',{staticClass:"pt-0 mt-0"})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","pl-3":""}},[_c('v-layout',{style:(_vm.$route.name == 'dashboard'
                ? 'background-color: #ffffff'
                : 'background-color: #f6fff9'),attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"min-height":"100vh","color":_vm.$route.name == 'dashboard' ? 'transparent' : '#FFFFFF',"flat":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }